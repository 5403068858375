.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
}

.init-container {
    display: flex;
    justify-content: space-around;
    width: 60%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #333;
}

.half-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.input-section,
label {
    width: 100%;
    text-align: center;
    margin: 10px 0;
}

label {
    margin-bottom: 5px;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background: #444444;
    border-radius: 50vh;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 50%;
    color: #ffffff;
}

input[type="range"]+.init-button {
    margin-top: 20px;
}

.init-button {
    padding: 12px 16px;
    margin-top: 10px;
    background-color: #202020;
    color: #ffffff;
    border-style: none;
    border-radius: 12px;
    cursor: pointer;
}

.init-button:hover {
    background-color: #3a3a3a;
}

.filter-container {
    border-radius: 16px;
    background-color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding-top: 50px;
    margin: auto;
    margin-top: 32px;
}

.bit-vector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bit-indexess,
.bit-vector {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.bit-indexess {
    padding-left: 20px;
}

.bit-index {
    box-sizing: border-box;
    width: 20px;
    text-align: center;
    margin: 0 2px;
    font-size: 12px;
}

.row-header {
    box-sizing: border-box;
    width: 20px;
    text-align: center;
    font-size: 12px;
}

.sketch-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bit {
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 2px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.set {
    background-color: #4CAF50;
}

.unset {
    background-color: #f44336;
}

.highlight {
    background-color: yellow;
    color: green;
}

.actions {
    justify-content: space-around;
    width: 100%;
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 24px;
}

.action-group {
    justify-content: center;
    width: 100%;
    display: flex;
    align-items: baseline;
}

.actions label {
    margin-right: 5px;
    width: max-content;
}

.action-input {
    height: 24px;
    width: 250px;
    border-radius: 50vh;
    background-color: #4b4b4b;
    color: #ffffff;
    border-style: none;
    padding: 8px 12px;
}

.real-objects {
    align-self: flex-start;
}

.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions span {
    margin-left: 10px;
}

.real-objects {
    display: flex;
    align-items: center;
}

.real-objects h3 {
    margin-right: 10px;
}

.items-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.statistics {
    margin-top: 10px;
    text-align: center;
}

.statistics p {
    margin: 5px 0;
}

.home-container {
    color: #ddd;
    padding: 20px;
}

.info-section {
    flex: 1;
}

.section-group {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 128px;
}

.section-column {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    flex: 1;
}

.section-text {
    text-align: justify;
}