.navbar {
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #333;
  border-radius: 0 0 16px 16px;
  padding: 0 16px;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-links a {
  display: flex;
  align-items: center;
  text-align: center;
  color: #ddd;
  text-decoration: none;
  padding: 10px 20px;
  transition: background-color 0.3s;
  margin-bottom: 2px;
}

.nav-links a:hover,
.nav-links a.active {
  margin-bottom: 0;
  color: #ddd;
  border-bottom: 2px solid #999999;
}

.logo-img {
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}