.skip-list-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
}

.level-container {
    display: flex;
    align-items: center;
    justify-content: start;
}

.nodes-container {
    display: flex;
    margin-left: 20px;
}

.node {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid black;
    width: 30px;
    min-width: 30px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.node-placeholder {
    opacity: 0;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid transparent;
    min-width: 30px;
}